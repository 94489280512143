import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const EXPERT_ADVICE_LOCALES = {
	WOLTAIR_CZ: 'poradna',
	WOLTAIR_PL: null,
	WOLTAIR_DE: null,
	WOLTAIR_IT: null
}

export const EXPERT_ADVICE = EXPERT_ADVICE_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return EXPERT_ADVICE === param
}) satisfies ParamMatcher
